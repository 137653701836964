exports.components = {
  "component---src-pages-about-tiny-tessa-tsx": () => import("./../../../src/pages/about-tiny-tessa.tsx" /* webpackChunkName: "component---src-pages-about-tiny-tessa-tsx" */),
  "component---src-pages-crochet-patterns-tsx": () => import("./../../../src/pages/crochet-patterns.tsx" /* webpackChunkName: "component---src-pages-crochet-patterns-tsx" */),
  "component---src-pages-free-patterns-tsx": () => import("./../../../src/pages/free-patterns.tsx" /* webpackChunkName: "component---src-pages-free-patterns-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inspiration-tsx": () => import("./../../../src/pages/inspiration.tsx" /* webpackChunkName: "component---src-pages-inspiration-tsx" */),
  "component---src-templates-portfolio-page-tsx": () => import("./../../../src/templates/portfolioPage.tsx" /* webpackChunkName: "component---src-templates-portfolio-page-tsx" */),
  "component---src-templates-redirect-page-tsx": () => import("./../../../src/templates/redirectPage.tsx" /* webpackChunkName: "component---src-templates-redirect-page-tsx" */)
}

